import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        firebase_uid: '',
        user_email: '',
        login: false,
        admin: false,
    },
    mutations: {
        setAdmin(state, data) {
            state.admin = data;
        },
        setLogin(state, data) {
            state.firebase_uid = data;
            state.login = true;
        },
        setLoginEmail(state, data) {
            state.user_email = data;
            state.login = true;
        },
        setLogout(state) {
            state.firebase_uid = '';
            state.user_email = '';
            state.login = false;
            state.admin = false;
        }
    },
    actions: {}
})
