<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  name: 'App',
  components: {

  }
}
</script>
<style>
#app {
  font-family: NanumSquare, AppleSDGothicNeo, sans-serif;
  max-width: 1920px;
  margin: 0 auto;
}
</style>
