import firebase from 'firebase/app'
import 'firebase/storage';
import "firebase/firestore";
import "firebase/auth";

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyA_GAkRD5sU9Qkk4lJJ-6tQ7mHl529-7VU",
    authDomain: "police-lab-dev.firebaseapp.com",
    projectId: "police-lab-dev",
    storageBucket: "police-lab-dev.appspot.com",
    messagingSenderId: "336363605578",
    appId: "1:336363605578:web:5d40b840467de3f14ce0b3"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);



