import Vue from 'vue'
import Router from 'vue-router'
import firebase from "firebase/app";
import "firebase/auth";
import store from "../store";


Vue.use(Router)

const router = new Router({
    routes: [
        {
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/main/Main.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/',
                    name: 'home',
                    component: () => import('../views/Home'),
                },
                {
                    path: '/businessView',
                    name: 'businessView',
                    component: () => import('../views/user/business/BusinessView'),
                    meta: {
                        title: '사업 소개'
                    }
                },
                {
                    path: '/noticeList',
                    name: 'noticeList',
                    component: () => import('../views/user/notice/NoticeList.vue'),
                    meta: {
                        title: '공지사항',

                    }
                },
                {
                    path: '/noticeView',
                    name: 'noticeView',
                    component: () => import('../views/user/notice/NoticeView.vue'),
                    meta: {
                        title: '공지사항'
                    }
                },
                {
                    path: '/ideaList',
                    name: 'ideaList',
                    component: () => import('../views/user/idea/IdeaList'),
                    meta: {
                        title: '기술 문의'
                    }
                },
                {
                    path: '/ideaView',
                    name: 'ideaView',
                    component: () => import('../views/user/idea/IdeaView'),
                    meta: {
                        title: '아이디어 접수'
                    }
                },
                {
                    path: '/ideaForm',
                    name: 'ideaForm',
                    component: () => import('../views/user/idea/IdeaForm'),
                    meta: {
                        title: '아이디어 작성'
                    }
                },
                {
                    path: '/ideaEdit/:id',
                    name: 'ideaEdit',
                    component: () => import('../views/user/idea/IdeaEdit'),
                    meta: {
                        title: '아이디어 수정'
                    }
                },
                {
                    path: '/requestForm',
                    name: 'requestForm',
                    component: () => import('../views/user/request/RequestForm'),
                    meta: {
                        title: '시제품 의뢰'
                    }
                },
                {
                    path: '/technicalList',
                    name: 'technicalList',
                    component: () => import('../views/user/technic/TechnicalList'),
                    meta: {
                        title: '기술 문의'
                    }
                },
                {
                    path: '/technicalView',
                    name: 'technicalView',
                    component: () => import('../views/user/technic/TechnicalView'),
                    meta: {
                        title: '기술 문의'
                    }
                },
                {
                    path: '/technicalForm',
                    name: 'technicalForm',
                    component: () => import('../views/user/technic/TechnicalForm'),
                    meta: {
                        title: '기술 문의'
                    }
                },

                {
                    path: '/rank2',
                    name: 'rank2',
                    component: () => import('../components/Rank2'),
                    meta: {
                        title: '기술 문의'
                    }
                },
            ]
        },
        // =============================================================================
        // ADMIN PAGE LAYOUTS
        // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/admin/Admin.vue'),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                {
                    path: '/admin',
                    name: 'admin',
                    redirect: '/admin/welcome'
                },
                {
                    path: '/admin/welcome',
                    name: 'admin-welcome',
                    component: () => import('../views/admin/Welcome'),
                    meta: {
                        title: '관리자',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/member-manage',
                    name: 'admin-member-manage',
                    component: () => import('../views/admin/manage/member/MemberManage'),
                    meta: {
                        title: '관리자/멤버 관리',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/partnerManage',
                    name: 'admin-partnerManage',
                    component: () => import('../views/admin/manage/partner/PartnerManage'),
                    meta: {
                        title: '관리자/협력사 관리',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/partner-registration',
                    name: 'admin-partner-registration',
                    component: () => import('../views/admin/manage/partner/PartnerRegistration'),
                    meta: {
                        title: '관리자/협력사 등록',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/partner-update',
                    name: 'admin-partner-update',
                    component: () => import('../views/admin/manage/partner/PartnerUpdate'),
                    meta: {
                        title: '관리자/협력사 수정',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/youtubeManageList',
                    name: 'adminYoutubeManageList',
                    component: () => import('../views/admin/manage/youtube/YoutubeManageList'),
                    meta: {
                        title: '관리자/영상 관리',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/youtube-registration',
                    name: 'admin-youtube-registration',
                    component: () => import('../views/admin/manage/youtube/YoutubeRegistration'),
                    meta: {
                        title: '관리자/영상 등록',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/youtube-update',
                    name: 'admin-youtube-update',
                    component: () => import('../views/admin/manage/youtube/YoutubeUpdate'),
                    meta: {
                        title: '관리자/협력사 수정',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/noticeManageList',
                    name: 'adminNoticeManageList',
                    component: () => import('../views/admin/board/notice/NoticeManageList'),
                    meta: {
                        title: '관리자/사업 소개 관리',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/noticeManageForm',
                    name: 'adminNoticeManageForm',
                    component: () => import('../views/admin/board/notice/NoticeManageForm'),
                    meta: {
                        title: '관리자/공지사항 관리',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/noticeManageView',
                    name: 'adminNoticeManageView',
                    component: () => import('../views/admin/board/notice/NoticeManageView.vue'),
                    meta: {
                        title: '관리자/공지사항 관리',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/noticeManageEdit',
                    name: 'adminNoticeManageEdit',
                    component: () => import('../views/admin/board/notice/NoticeManageEdit'),
                    meta: {
                        title: '관리자/공지사항 관리',
                        authRequired: true,
                    }
                },
                // {
                //     path: '/admin/ideaManageList',
                //     name: 'adminIdeaManageList',
                //     component: () => import('../views/admin/board/idea/IdeaManageList'),
                //     meta: {
                //         title: '관리자/아이디어 접수 관리',
                //         authRequired: true,
                //     }
                // },
                {
                    path: '/admin/ideaManageList/:year',
                    name: 'adminIdeaManageList',
                    component: () => import('../views/admin/board/idea/IdeaManageList'),
                    meta: {
                        title: '관리자/아이디어 접수 관리',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/ideaBoardOnOff',
                    name: 'adminIdeaBoardOnOff',
                    component: () => import('../views/admin/board/idea/IdeaBoardOnOff.vue'),
                    meta: {
                        title: '관리자/아이디어 접수 활성화',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/ideaManageView',
                    name: 'adminIdeaManageView',
                    component: () => import('../views/admin/board/idea/IdeaManageView'),
                    meta: {
                        title: '관리자/아이디어 접수 관리',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/requestManageList',
                    name: 'adminRequestManageList',
                    component: () => import('../views/admin/board/request/RequestManageList'),
                    meta: {
                        title: '관리자/시제품 의뢰 관리',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/requestManageView',
                    name: 'adminRequestManageView',
                    component: () => import('../views/admin/board/request/RequestManageView'),
                    meta: {
                        title: '관리자/시제품 의뢰 관리',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/technicalManageList',
                    name: 'adminTechnicalManageList',
                    component: () => import('../views/admin/board/technical/TechnicalManageList'),
                    meta: {
                        title: '관리자/기술 문의 관리',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/technicalManageView',
                    name: 'adminTechnicalManageView',
                    component: () => import('../views/admin/board/technical/TechnicalManageView'),
                    meta: {
                        title: '관리자/기술 문의 관리',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/account-manage',
                    name: 'admin-account-manage',
                    component: () => import('../views/admin/account/AccountManage'),
                    meta: {
                        title: '관리자/계정 관리',
                        authRequired: true,
                    }
                },
                {
                    path: '/admin/privacy-manage',
                    name: 'admin-privacy-manage',
                    component: () => import('../views/admin/account/PrivacyManage'),
                    meta: {
                        title: '관리자/정책 관리',
                        authRequired: true,
                    }
                },

            ],
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                {
                    path: '/admin/login',
                    name: 'adminLogin',
                    component: () => import('../views/pages/Login'),
                    meta: {
                        title: '로그인',
                    }
                },
                {
                    path: '/user/terms',
                    name: 'userTerms',
                    component: () => import('../views/pages/Terms'),
                    meta: {
                        title: '약관동의',
                    }
                },
                {
                    path: '/user/signUp',
                    name: 'userSignUp',
                    component: () => import('../views/pages/SignUp'),
                    meta: {
                        title: '회원가입',
                    }
                },
                {
                    path: '/user/login',
                    name: 'userLogin',
                    component: () => import('../views/pages/UserLogin'),
                    meta: {
                        title: '로그인',
                    }
                },
                {
                    path: '/test',
                    name: 'test',
                    component: () => import('../views/pages/Test'),
                    meta: {
                        title: 'Test',
                    }
                },
                {
                    path: '/pages/error-404',
                    name: 'pages-error-404',
                    component: () => import('../views/pages/Error404.vue'),
                    meta: {
                        title: '404',
                    }
                },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ]
})

const makeTitle = (title) =>
    title ? `${title} | 폴리스랩2.0 드론` : "폴리스랩2.0 드론";

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = makeTitle(to.meta.title);
    });
});


router.beforeEach((to, from, next) => {
    firebase.auth().onAuthStateChanged((user) => {

        const firebaseCurrentUser = firebase.auth().currentUser

        if (user) {
            const uid = user.uid;
            const email = user.email;
            store.commit('setLogin', uid)
            // console.log(store.state.firebase_uid)
            store.commit('setLoginEmail', email)
            // console.log(store.state.user_email)
            // console.log(store.state.login)
            // console.log(firebaseCurrentUser.displayName);

            if (firebaseCurrentUser.displayName === 'USER') {
                store.commit('setAdmin', false)
                // console.log('USER');
            } else {
                store.commit('setAdmin', true)
                // console.log('ADMIN');
            }

        } else {
            store.commit('setLogout')
            // console.log('GUEST');
            // console.log('로그인 안됨');
            // console.log(store.state.firebase_uid)
            // console.log(store.state.user_email)
            // console.log(store.state.login)
            // console.log(store.state.admin)
        }


// if (
//     to.path === "/pages/login"
//     (auth.isAuthenticated() || firebaseCurrentUser)
// ) {
//     return next('/');
// }

        if (to.meta.authRequired) {
            if (!(firebaseCurrentUser)) {
                router.push({path: '/admin/login'})
            } else {
                if (firebaseCurrentUser.displayName === 'USER') {
                    router.push({path: '/admin/login'})
                    // router.push({path: '/admin/login', query: {to: to.path}})
                }
            }
        }

        return next()
// Specify the current path as the customState parameter, meaning it
// will be returned to the application after auth
// auth.login({ target: to.path });

    })

})
;


export default router
